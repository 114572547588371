<template>
  <div class="content-container">
    <card-title :title="payType"></card-title>
    <div class="fee">
      <span>金额:</span>
      <span class="num">{{ needCost }}</span>
    </div>
    <div class="container">
      <a-row v-for="item in payConfig" :key="item.label" type="flex">
        <a-col :span="7" class="label">
          <!--<span class="label">-->
          {{ item.label }}
          <!--</span>-->
        </a-col>
        <a-col span="17">
          <span>{{ item.value }}</span>
        </a-col>
        <!--<div class="config">-->
        <!--  <div v-for="item in payConfig" :key="item.label">-->
        <!--    <span class="label">{{ item.label }}</span>-->
        <!--    <span>{{ item.value }}</span>-->
        <!--  </div>-->
        <!--</div>-->
      </a-row>
      <a-row justify="center" type="flex">
        <a-col span="12">
          <a-button
            type="primary"
            shape="round"
            block
            size="large"
            class="btn"
            @click="toOrders"
            >知道了</a-button
          >
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import { Button, Row, Col } from "ant-design-vue";
import { PayType } from "@/views/pay/payConfig";
import { formatCentToYuan } from "../../utils/format";
import { bankOrderDetails } from "../../request/package";

export default {
  components: {
    CardTitle,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      needCost: 0,
      payType: PayType.bank.name,
      payConfig: [
        {
          label: "开户名：",
          value: "",
        },
        {
          label: "开户行：",
          value: "",
        },
        {
          label: "银行账号：",
          value: "",
        },
        {
          label: "订单号：",
          value: "",
        },
        {
          label: "提示：",
          value: "",
        },
      ],
    };
  },
  mounted() {
    this.getPayData();
  },
  methods: {
    getPayData() {
      const { id } = this.$route.query;
      id && this.getPayDetails(id);
    },
    getPayDetails(id) {
      bankOrderDetails(id).then((res) => {
        console.log(res);
        const {
          bankAccount,
          bankDeposit,
          companyName,
          orderNo,
          message,
          needCost,
        } = res;
        this.needCost = formatCentToYuan(needCost);
        this.payConfig.forEach((item, index) => {
          item.value =
            index === 0
              ? companyName
              : index === 1
              ? bankDeposit
              : index === 2
              ? bankAccount
              : index === 3
              ? orderNo
              : message;
        });
      });
    },
    toOrders() {
      this.$router.replace("/user/order");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.fee {
  font-size: $font-size-card-title;
  color: $primary-text-color;
  text-align: center;
  margin-top: 56px;
  margin-bottom: 50px;

  .num {
    color: #f47755;
  }
}

.container {
  width: 520px;
  margin: 0 auto;
  font-size: 16px;
  color: $primary-text-color;
  line-height: 36px;

  .label {
    text-align: right;
  }
}

.btn {
  margin-top: 60px;
}
</style>
